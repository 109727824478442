<template>
  <div class="inner-layout">

    <div class="page-title">마이페이지</div>

    <mypage-tab></mypage-tab>

    <div class="member-layout">
      <div class="input-title">새 비밀번호<span class="color-1">*</span></div>
      <input-field v-model="password1" placeholder="새 비밀번호" type="password" :errorMessage="password1Msg" maxLength="20"/>
      <div class="input-title">새 비밀번호 확인<span class="color-1">*</span></div>
      <input-field v-model="password2" placeholder="새 비밀번호 확인" type="password" class="mt-10" :errorMessage="password2Msg" maxLength="20"/>
      <div class="mt-15">
        <button class="btn btn-x-large color-1 btn-block" @click="save">비밀번호 변경</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
export default {
  name: "mypagePassword",
  data: function () {
    return {
      password1: '',
      password1Msg: null,
      password2: '',
      password2Msg: null,
    }
  },
  computed: {
    ...mapGetters({
      getEmail: 'session/getEmail',
      getTarget: 'session/getTarget',
    }),
  },
  watch: {
    password1() {
      this.password1Msg = null
    },
    password2() {
      this.password2Msg = null
    }
  },
  created() {
    if (this.getTarget !== 'email') location.href = '/'
  },
  methods:{
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    validate() {
      if (!this.password1) {
        this.password1Msg = this.$msg('required.input')
        return false
      }
      if (!this.password1.patternCheck('password')) {
        this.password1Msg = this.$msg('password.check')
        return false
      }
      if (!this.password2 || this.password1 !== this.password2) {
        this.password2Msg = this.$msg('password.no-match')
        return false
      }
      return true
    },
    async save() {
      if (this.validate()) {
        const { result } = await this.$api.setPassword({
          email: this.getEmail,
          password: this.password1,
        })
        if (result === 'success') this.alertPopup()
      }
    },
    alertPopup() {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('ok.update'),
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          this.password1 = ''
          this.password2 = ''
        },
      })
    },
  },
}
</script>
